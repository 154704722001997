<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <div class="d-flex align-center">
                        <v-avatar color="primary" :class="'v-avatar-light-bg white--text'" size="32">
                            <span>PR</span>
                        </v-avatar>
                        <div class="d-flex flex-column ms-3">
                            <span class="d-block font-weight-semibold text--primary text-truncate">
                                {{ FuncionesGenerales.camelCase(datos.producto.producto.nombre) }}
                            </span>
                            <small v-if="datos.idLote"> {{
                                FuncionesGenerales.camelCase(datos.producto.lote.numeroLote)
                            }} </small>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="form">
                            <v-row>

                              
                                <v-col cols="12" md="6">
                                    <v-text-field hide-details="auto" dense autofocus label="Cant ajustada" outlined
                                        v-model="totalUnidades" :rules="reglas.totalUnidades"
                                        @keyup="calcularExistencia(1)" required
                                        @keydown.enter="modificar()"></v-text-field>

                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field hide-details="auto" dense label="Existencia Final" outlined
                                        v-model="existenciaFinal" required @keydown.enter="modificar()"
                                        :rules="reglas.existenciaFinal" @keyup="calcularExistencia(2)"></v-text-field>

                                </v-col>
                                <v-col cols="12">
                                    <v-textarea label="Observacion" outlined dense rows="3"
                                        v-model="observacion"></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                     
                                                    <th class="text-left">
                                                        Existencia Actual
                                                    </th>
                                                    <th class="text-left">
                                                        Cantidad ajustada
                                                    </th>
                                                    <th class="text-left">
                                                        Existencia Final
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr> 
                                                    <th>{{ datos.existenciaActual }}</th>
                                                    <th>{{ Number(totalUnidades) }}</th>
                                                    <th>{{ Number(existenciaFinal) }}</th>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secundary" @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" @click="eliminar()">
                        Borrar
                    </v-btn>
                    <v-btn color="primary" @click="modificar()">
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import store from '@/store'
export default {
    components: {
    },
    setup(props, context) {
        const dialog = ref(false)
        const datos = ref({})
        const cantidad = ref(0)
        const existenciaFinal = ref('0')
        const totalUnidades = ref('0')
        const observacion = ref('')
        const abrir = (dato) => {
            dialog.value = true
            datos.value = dato
            console.log(datos.value)
            totalUnidades.value = datos.value.totalUnidades.toString()
            existenciaFinal.value = datos.value.existenciaFinal.toString()
            observacion.value = datos.value.observacion 
        }
        const calcularExistencia = (value) => {
            if (value == 2) {
                totalUnidades.value = (Number(existenciaFinal.value) - Number(datos.value.existenciaActual)).toString()
            }

            if (value == 1) {
                existenciaFinal.value = (Number(datos.value.existenciaActual) + Number(totalUnidades.value)).toString()
            }
        }
        const reglas = {
            motivo: [
                v => !!v || "Es requerido",
            ],
            totalUnidades: [
                v => !!v || "Es requerido",
                v => /^-?\d*(\.\d+)?$/.test(v) || "No es un valor valido",
            ],
            existenciaFinal: [
                v => !!v || "Es requerido",
                v => /^-?\d*(\.\d+)?$/.test(v) || "No es un valor valido",
            ],
        };
        const form = ref(null)

        const validarForm = () => {
            let val = form.value?.validate()
            return val
        }
        const modificar = () => {
            if (validarForm()) {

                datos.value.totalUnidades = Number(totalUnidades.value)
                datos.value.existenciaFinal = Number(existenciaFinal.value) 
                datos.value.observacion = observacion.value
               
                datos.value.entradas = Number(totalUnidades.value) > 0 ? Number(totalUnidades.value) : 0
                datos.value.salidas = Number(totalUnidades.value) < 0 ? (Number(totalUnidades.value) * -1) : 0
                dialog.value = false

            } else {
                store.commit('setAlert', {
                    message: "Verifique los datos",
                    type: 'info',
                })
            }


        }
        const eliminar = () => {

        }
        return {
            FuncionesGenerales,
            store,
            dialog,
            abrir,
            datos,
            modificar,
            eliminar,
            calcularExistencia,
            existenciaFinal,
            totalUnidades,
            form,
            reglas,
            observacion
        }
    }
}
</script>