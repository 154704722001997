<template>
  <v-row>
    <v-col cols="12">
      <Actualizar @GetListar="GetListar" ref="ActualizarRef"></Actualizar>
    </v-col>

    <v-col cols="12">
      <Listar  ref="ListarRef" @GetEditar="GetEditar"></Listar>
    </v-col>


  </v-row>

</template>

<script>
import Actualizar from './componentes/Actualizar.vue';
import Listar from './componentes/Listar.vue';
import ViewAjuste from './componentes/ViewAjuste.vue';
import { ref } from '@vue/composition-api';
export default {
  components: {
    Actualizar,
    Listar,
    ViewAjuste
  },
  setup() {
    const ActualizarRef = ref(null)
    const ListarRef = ref(null)
    const GetEditar = (item) => {
      ActualizarRef.value.GetEditar(item)
    }
    const GetListar = (item) => {
      ListarRef.value.cargar()
    }
    
    return {
      GetEditar,
      ActualizarRef,
      ListarRef,
      GetListar
    }
  }
}
</script>
